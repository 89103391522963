/* You can add global styles to this file, and also import other style files */
@import './styles/variables';
@import './styles/theme';
@import './styles/elements';
@import './styles/table';
@import './styles/sidebar';
@import './styles/paginator';
@import './styles/form';
@import './styles/collapse_panel';
@import './styles/dialog';
@import './styles/list_view';
@import './styles/form_view';
