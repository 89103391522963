.collapse-bar {
  --back: var(--sidebarBackground, var(--background));
  position: relative;
  display: flex;
  place-content: center;
  background-color: var(--collapseExpand);
  color: var(--background);
  border-radius: 0 0 $borderRadius $borderRadius;
  cursor: pointer;
  user-select: none;
  // prettier-ignore
  box-shadow:
    0 3px 5px rgb(0 0 0 / 10%) inset,
    // Faux border reset on parent to make bar look inset
    -6px 1px 0px 1px var(--back),
    6px 1px 0px 1px var(--back);
  margin: 0 2px;
  height: 1rem;
  .mat-icon {
    height: 1.2rem;
    &.material-icons {
      padding: 0 3px;
      line-height: 1.3rem;
    }
  }
}
