// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'sass:map';

// Plus imports for other components in your app.
@import './variables';
@import './mixins';

@font-face {
  font-family: 'ABCDiatype';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/ABCDiatype-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'ABCDiatype';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/ABCDiatype-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'ABCDiatype Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/ABCDiatypeMono-Regular.woff2') format('woff2');
}
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.
$light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      use-system-variables: true,
      system-variables-prefix: sys,
    ),
    typography: (
      use-system-variables: true,
      system-variables-prefix: sys,
    ),
  )
);
$dark-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      use-system-variables: true,
      system-variables-prefix: sys,
    ),
    typography: (
      use-system-variables: true,
      system-variables-prefix: sys,
    ),
  )
);
@include mat.elevation-classes();
@include mat.app-background();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@mixin darkTheme() {
  @each $key, $color in $dark {
    --#{$key}: #{$color};
  }
}
@mixin lightTheme() {
  @each $key, $color in $light {
    --#{$key}: #{$color};
  }
}

// prettier-ignore
:root {
  @include lightTheme();
  --baseFont: ABCDiatype;
  --mat-autocomplete-background-color: var(--background);
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-container-shape: 4px;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-font: var(--baseFont);
  --mat-chip-selected-trailing-icon-color: var(--background);
  --mat-datepicker-calendar-container-background-color: var(--background);
  --mat-datepicker-calendar-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-date-disabled-state-text-color: var(--muted);
  --mat-datepicker-calendar-container-text-color: var(--textColor);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-header-divider-color: transparent;
  --mat-dialog-with-actions-content-padding: 0 2rem 3px;
  --mat-divider-color: #c4c6d0;
  --mat-divider-width: 1px;
  --mat-fab-small-disabled-state-container-color: var(--muted);
  --mat-fab-small-disabled-state-foreground-color: var(--background);
  --mat-fab-small-foreground-color: var(--background);
  --mat-filled-button-focus-state-layer-opacity: .12;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-hover-state-layer-opacity: .08;
  --mat-filled-button-icon-offset: -4px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-pressed-state-layer-opacity: .12;
  --mat-form-field-container-height: 40px;
  --mat-form-field-container-text-line-height: 1.35;
  --mat-form-field-container-text-size: 1.1rem;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-state-layer-color: black;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-legacy-button-toggle-height: 26px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-list-list-item-leading-icon-end-space: 16px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-mdc-form-field-floating-label-scale: 0.9;
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-label-text-line-height: 1rem;
  --mat-option-selected-state-label-text-color: #00313e;
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-icon-offset: -4px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-state-layer-color: black;
  --mat-paginator-container-background-color: var(--panelBackLight);
  --mat-paginator-container-size: 48px;
  --mat-paginator-container-text-line-height: 1rem;
  --mat-paginator-container-text-size: 0.9rem;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-select-trigger-text-size: 0.9rem;
  --mat-protected-button-focus-state-layer-opacity: .12;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-hover-state-layer-opacity: .04;
  --mat-protected-button-icon-offset: -4px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-pressed-state-layer-opacity: .12;
  --mat-radio-checked-ripple-color: #b6c2ff;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-ripple-color: black;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-focused-arrow-color: rgba(0, 49, 62, 0.87);
  --mat-select-invalid-arrow-color: rgba(155, 58, 40, 0.87);
  --mat-select-panel-background-color: white;
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-trigger-text-size: 13px;
  --mat-snack-bar-button-color: #abc7ff;
  --mat-standard-button-toggle-divider-color: var(--primary);
  --mat-standard-button-toggle-focus-state-layer-opacity: .12;
  --mat-standard-button-toggle-height: 24px;
  --mat-standard-button-toggle-hover-state-layer-opacity: .04;
  --mat-standard-button-toggle-selected-state-background-color: var(--primary);
  --mat-standard-button-toggle-selected-state-text-color: var(--background);
  --mat-standard-button-toggle-shape: 4px;
  --mat-table-header-headline-line-height: 1.4rem;
  --mat-table-header-headline-size: 0.8rem;
  --mat-table-header-headline-tracking: normal;
  --mat-table-header-headline-weight: 400;
  --mat-table-row-item-label-text-size: 1rem;
  --mat-table-row-item-label-text-weight: 400;
  --mat-text-button-focus-state-layer-opacity: .12;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-hover-state-layer-opacity: .08;
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-icon-offset: 0;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-pressed-state-layer-opacity: .12;
  --mat-text-button-state-layer-color: black;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-toolbar-mobile-height: 56px;
  --mat-toolbar-standard-height: 64px;
  --mdc-checkbox-disabled-selected-icon-color: var(--muted);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000aff;
  --mdc-checkbox-selected-focus-icon-color: #b6c2ff;
  --mdc-checkbox-selected-focus-state-layer-color: #b6c2ff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-hover-icon-color: #b6c2ff;
  --mdc-checkbox-selected-hover-state-layer-color: #b6c2ff;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-icon-color: #b6c2ff;
  --mdc-checkbox-selected-pressed-icon-color: #b6c2ff;
  --mdc-checkbox-selected-pressed-state-layer-color: #b6c2ff;
  --mdc-checkbox-selected-pressed-state-layer-opacity: .12;
  --mdc-checkbox-state-layer-size: 25px;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: var(--primary);
  --mdc-chip-outline-width: 0;
  --mdc-chip-selected-label-text-color: var(--background);
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-selected-icon-color: var(--background);
  --mdc-circular-progress-active-indicator-color: var(--primary);
  --mdc-fab-small-container-color: var(--primary);
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-filled-button-container-height: 26px;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-filled-button-label-text-font: var(--baseFont);
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-disabled-label-text-color: var(--muted);
  --mdc-filled-text-field-label-text-size: 1rem;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: var(--textColor);
  --mdc-list-list-item-disabled-leading-icon-color: var(--textColor);
  --mdc-list-list-item-disabled-trailing-icon-color: var(--textColor);
  --mdc-list-list-item-focus-label-text-color: var(--textColor);
  --mdc-list-list-item-focus-state-layer-color: var(--textColor);
  --mdc-list-list-item-focus-state-layer-opacity: 0.2;
  --mdc-list-list-item-hover-label-text-color: var(--textColor);
  --mdc-list-list-item-hover-leading-icon-color: var(--textColor);
  --mdc-list-list-item-hover-state-layer-color: var(--textColor);
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-hover-trailing-icon-color: var(--textColor);
  --mdc-list-list-item-label-text-color: var(--textColor);
  --mdc-list-list-item-leading-icon-color: var(--textColor);
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-one-line-container-height: 2rem;
  --mdc-list-list-item-selected-trailing-icon-color: var(--textColor);
  --mdc-list-list-item-supporting-text-color: var(--textColor);
  --mdc-list-list-item-supporting-text-font: var(--baseFont);
  --mdc-list-list-item-supporting-text-line-height: var(--mdc-list-list-item-leading-icon-size);
  --mdc-list-list-item-trailing-icon-color: var(--textColor);
  --mdc-list-list-item-trailing-supporting-text-color: var(--textColor);
  --mdc-outlined-button-container-height: 26px;
  --mdc-outlined-button-container-shape: 4px;
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-outlined-text-field-hover-outline-color: transparent;
  --mdc-plain-tooltip-container-color: black;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-color: var(--background);
  --mdc-plain-tooltip-supporting-text-line-height: 1rem;
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-tracking: 0.025rem;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-protected-button-container-color: var(--primary);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-height: 26px;
  --mdc-protected-button-container-shadow-color: #000;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-keep-touch-target: false;
  --mdc-protected-button-label-text-color: var(--background);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-selected-focus-icon-color: #b6c2ff;
  --mdc-radio-selected-hover-icon-color: #b6c2ff;
  --mdc-radio-selected-icon-color: #b6c2ff;
  --mdc-radio-selected-pressed-icon-color: #b6c2ff;
  --mdc-radio-state-layer-size: 40px;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-snackbar-container-color: var(--primary);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-supporting-text-color: var(--background);
  --mdc-text-button-container-height: 32px;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-text-button-label-text-size: 1rem;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-text-button-label-text-transform: none;
  --mdc-text-button-label-text-weight: 500;
  --mdc-switch-selected-focus-state-layer-color: #343dff;
  --mdc-switch-selected-handle-color: #ffffff;
  --mdc-switch-selected-hover-state-layer-color: #343dff;
  --mdc-switch-selected-pressed-state-layer-color: #343dff;
  --mdc-switch-selected-focus-handle-color: #e0e0ff;
  --mdc-switch-selected-hover-handle-color: #e0e0ff;
  --mdc-switch-selected-pressed-handle-color: #e0e0ff;
  --mdc-switch-selected-focus-track-color: #343dff;
  --mdc-switch-selected-hover-track-color: #343dff;
  --mdc-switch-selected-pressed-track-color: #343dff;
  --mdc-switch-selected-track-color: #343dff;
  --mdc-switch-selected-icon-color: #00006e;
  --mat-switch-track-outline-color: #74777f;
  --mat-switch-disabled-unselected-track-outline-color: #1a1b1f;
  --mat-switch-label-text-color: #1a1b1f;
  --mdc-switch-selected-focus-state-layer-opacity: .12;
  --mdc-switch-selected-hover-state-layer-opacity: .08;
  --mdc-switch-selected-pressed-state-layer-opacity: .12;
  --mdc-switch-unselected-focus-state-layer-opacity: .12;
  --mdc-switch-unselected-hover-state-layer-opacity: .08;
  --mdc-switch-unselected-pressed-state-layer-opacity: .12;
  --mdc-switch-selected-focus-state-layer-color: #005cbb;
  --mdc-switch-selected-handle-color: #ffffff;
  --mdc-switch-selected-hover-state-layer-color: #005cbb;
  --mdc-switch-selected-pressed-state-layer-color: #005cbb;
  --mdc-switch-selected-focus-handle-color: #d7e3ff;
  --mdc-switch-selected-hover-handle-color: #d7e3ff;
  --mdc-switch-selected-pressed-handle-color: #d7e3ff;
  --mdc-switch-selected-focus-track-color: #005cbb;
  --mdc-switch-selected-hover-track-color: #005cbb;
  --mdc-switch-selected-pressed-track-color: #005cbb;
  --mdc-switch-selected-track-color: #005cbb;
  --mdc-switch-disabled-selected-handle-color: #fdfbff;
  --mdc-switch-disabled-selected-icon-color: #1a1b1f;
  --mdc-switch-disabled-selected-track-color: #1a1b1f;
  --mdc-switch-disabled-unselected-handle-color: #1a1b1f;
  --mdc-switch-disabled-unselected-icon-color: #e0e2ec;
  --mdc-switch-disabled-unselected-track-color: #e0e2ec;
  --mdc-switch-selected-icon-color: #001b3f;
  --mdc-switch-unselected-focus-handle-color: #44474e;
  --mdc-switch-unselected-focus-state-layer-color: #1a1b1f;
  --mdc-switch-unselected-focus-track-color: #e0e2ec;
  --mdc-switch-unselected-handle-color: #74777f;
  --mdc-switch-unselected-hover-handle-color: #44474e;
  --mdc-switch-unselected-hover-state-layer-color: #1a1b1f;
  --mdc-switch-unselected-hover-track-color: #e0e2ec;
  --mdc-switch-unselected-icon-color: #e0e2ec;
  --mdc-switch-unselected-pressed-handle-color: #44474e;
  --mdc-switch-unselected-pressed-state-layer-color: #1a1b1f;
  --mdc-switch-unselected-pressed-track-color: #e0e2ec;
  --mdc-switch-unselected-track-color: #e0e2ec;
  --mdc-switch-disabled-selected-icon-opacity: .38;
  --mdc-switch-disabled-track-opacity: .12;
  --mdc-switch-disabled-unselected-icon-opacity: .38;
  --mdc-switch-handle-shape: 9999px;
  --mdc-switch-selected-icon-size: 16px;
  --mdc-switch-track-height: 20px;
  --mdc-switch-track-shape: 9999px;
  --mdc-switch-track-width: 42px;
  --mdc-switch-unselected-icon-size: 16px;
  --mdc-switch-state-layer-size: 40px;
  --mat-switch-track-outline-color: #74777f;
  --mat-switch-disabled-unselected-track-outline-color: #1a1b1f;
  --mat-switch-label-text-color: #1a1b1f;
  --mat-switch-label-text-font: Roboto, sans-serif;
  --mat-switch-label-text-line-height: 1.25rem;
  --mat-switch-label-text-size: .875rem;
  --mat-switch-label-text-tracking: .016rem;
  --mat-switch-label-text-weight: 400;
  --mat-switch-disabled-selected-handle-opacity: 1;
  --mat-switch-disabled-unselected-handle-opacity: .38;
  --mat-switch-unselected-handle-size: 16px;
  --mat-switch-selected-handle-size: 24px;
  --mat-switch-pressed-handle-size: 28px;
  --mat-switch-with-icon-handle-size: 24px;
  --mat-switch-selected-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 22px;
  --mat-switch-unselected-handle-horizontal-margin: 0 8px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 0;
  --mat-switch-visible-track-transition: opacity 75ms;
  --mat-switch-hidden-track-transition: opacity 75ms;
  --mat-switch-track-outline-width: 2px;
  --mat-switch-selected-track-outline-width: 2px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 2px;
}

.mat-mdc-raised-button:not(:disabled) {
  --mdc-protected-button-container-color: var(--primary);
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
  background-color: var(--background);
}

.mat-chip.mat-standard-chip {
  border-radius: $borderRadius;
  &.mat-primary.mat-chip-selected .mat-chip-remove {
    color: var(--background);
    opacity: 0.8;
  }
}

.mat-list-base {
  .mat-list-item {
    height: 3.5rem !important;
  }
  &.mat-nav-list {
    .mat-subheader {
      margin: 0;
      font-size: 1.4rem;
    }
    .mat-list-item {
      height: 2rem !important;
      color: var(--accentText);
    }
    .mat-subheader,
    .mat-list-item .mat-list-item-content {
      padding: 0.5rem 0 !important;
      .mat-icon {
        height: 1rem;
        font-size: 1.5rem;
        line-height: 1rem;
      }
    }
  }
}
.mat-typography {
  .mat-icon,
  .mat-list-base .mat-list-item .mat-list-icon {
    width: 2rem;
    height: 2rem;
  }
}

lib-language-selector {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mat-mdc-form-field {
    .mdc-text-field {
      padding: 0;
      background-color: transparent;
      .mat-mdc-form-field-flex {
        padding: 0 8px;
        .mat-mdc-form-field-infix {
          padding: 0 !important;
          min-height: 3.25rem;
          display: flex;
          place-items: flex-end;
        }
      }
    }
    .mat-mdc-form-field-icon-prefix > .mat-icon {
      padding: 0;
    }
  }
}
.mat-mdc-select-panel .mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
  padding-left: 16px;
}
.mat-mdc-autocomplete-panel .mat-mdc-option {
  min-height: 28px;
}
.mdc-button__label {
  display: flex;
  place-items: center;
}
