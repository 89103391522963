@import 'variables';

.form-view {
  padding: 1rem;
  @media screen and (min-width: $break1) {
    display: grid;
    grid-template:
      'header toolbar indicator' 6rem
      'form   form    form     ' 1fr
      / 15rem minmax(10rem, 1fr) minmax(25rem, 30rem);
    height: 100vh;
  }
  @media screen and (max-width: $break1) {
    display: block;
    // grid-template:
    //   'header toolbar indicator' 6rem
    //   'form   form    form     ' 1fr
    //   'side   side    side     ' auto
    //   / 13rem 1fr auto;
    overflow: auto;
    max-height: 100vh;
  }

  // First row  --------------------------------------------------------------
  > header {
    grid-area: header;
    width: 100%;
    place-self: center;
    h2 {
      display: flex;
      place-items: center;
      margin: 0;
      a {
        color: inherit;
      }
      .mat-mini-fab {
        margin-right: 1rem;
      }
    }
  }

  .toolbar {
    grid-area: toolbar;
    place-self: center;
  }

  .indicator {
    grid-area: indicator;
    place-self: center;
    span {
      display: flex;
      place-items: center;
      animation: fadeIn 3s linear;
    }
  }
  // End first row  ----------------------------------------------------------

  // Second row  --------------------------------------------------------------
  > bs-sidebar-container,
  > main {
    grid-area: form;
  }
  > main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3rem calc(100vh - 14rem) 3rem;
  }
  form {
    // margin-right: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3rem calc(100vh - 14rem) 3rem;
    height: 100%;
    @media screen and (max-width: $break1) {
      margin-right: 0;
      grid-template-rows: auto;
    }
    header {
      h1 {
        margin: 0;
      }
    }
    > section {
      border-right: 1px solid var(--primary);
      padding-right: 1rem;
      // padding-top: 16px;
      overflow: auto;
      // max-height: calc(100vh - 20rem);
      margin-bottom: 1rem;
      contain: strict;
      @media screen and (max-width: $break1) {
        overflow: initial;
        border-right: 0;
        padding-right: 0;
      }
      @media screen and (max-width: $break1) {
        bs-collapsible-panel > header {
          top: -13px;
        }
      }
      .collapsible-panel-content {
        display: grid;
        --vGap: 10rem;
        // grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        @media screen and (min-width: $break2) {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: masonry;
        }
        @container (max-width: 79rem) {
          --vGap: 5rem;
        }
        @container (max-width: 65rem) {
          grid-template-columns: 1fr;
        }
        gap: 0 var(--vGap);

        .f-column,
        .f-row {
          display: flex;
          gap: 0 var(--vGap);
          @container (max-width: 65rem) {
            display: block;
          }
        }
        .f-column {
          flex-direction: column;
        }
        .f-row {
          > * {
            flex: 1;
          }
        }
        .mat-radio-button {
          margin: 0 2rem 1rem 0;
        }
      }
    }
    .full .collapsible-panel-content {
      display: flex;
      flex-direction: column;
    }
    footer {
      margin-bottom: 1rem;
    }
  }

  .mat-chip-list {
    width: calc(100% + 0.6rem);
    .mat-chip-list-wrapper {
      margin: 0;
      background-color: var(--background);
      gap: 4px;
      .mat-chip {
        margin: 0;
        padding: 5px 5px 5px 12px;
        min-height: 26px;
      }
    }
  }
}
