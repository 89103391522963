@import 'variables';

.list-view {
  flex: 1;
  display: grid;
  grid-template-rows: 0 1fr;
  padding: 0rem 0 1rem 1rem;
  > header {
    display: flex;
    margin: 0;
    > h1 {
      flex: 1;
      margin: -3.5rem 0 0 0;
      text-align: center;
    }
  }
  .toolbar {
    background: var(--panelBackLight);
    border-radius: $borderRadius;
    padding: 1rem;
    display: flex;
    gap: 1.5rem;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0;
    height: 88px;
    .mat-mdc-form-field.no-hint {
      .mat-mdc-form-field-flex {
        background-color: var(--background);
        border-radius: var(--borderRadius, 4px) var(--borderRadius, 4px) 0 0;
      }
      // .mat-mdc-form-field-suffix .mat-mdc-icon-button {
      //   width: 2.3rem;
      //   height: 2.3rem;
      // }
    }
    article {
      display: flex;
      flex-direction: column;
      place-content: space-between;
      label {
        margin-bottom: 0;
        display: flex;
      }
      section {
        display: flex;
        gap: 0.5rem;
      }
    }
    .tools {
      display: flex;
      flex-direction: row;
      place-items: center;
      justify-content: flex-start;
      width: 244px;
    }
    .status {
      height: 5rem;
      flex-direction: row;
      gap: 0.5rem;
    }
    .spacer {
      flex: 1;
    }
    .search {
      .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        min-height: 38px;
        padding: 10px 0;
      }
    }
  }
  .mat-mdc-form-field {
    .mat-mdc-form-field-flex {
      place-items: center;
    }
    .mat-mdc-form-field-underline {
      display: none;
    }
  }
  main {
    display: grid;
    grid-template-rows: 1fr 4rem;
    gap: 0.3rem;
    margin-right: 0.5rem;
    overflow: hidden;
    position: relative;
    &:has(bs-filter:not(.open)) {
      margin-left: -0.3rem;
    }
    .content > .table > .loader,
    > .table > .loader,
    > .loader {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.6);
      height: 100%;
      display: flex;
      place-content: center;
      place-items: center;
    }
    .content {
      display: flex;
      flex-direction: column;
    }
    .table {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: column;
      border-radius: $borderRadius;
    }
    > footer {
      place-self: center;
      grid-column: 1 / -1;
      .mat-mdc-form-field-wrapper {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
  bs-sidebar-container:not(.open) main > footer .mat-mdc-paginator {
    margin-right: -2.2rem;
  }
  .mat-mdc-paginator-range-label {
    width: 32px;
    margin: 0 24px;
  }
}
