// Brand colors
$primary: #00313e; // Primary theme color
$accent: #0032ff; // Accent theme color
$todo: #e69419;
$warning: #9b3a28;
$success: #72a98f;

// Theme
$light: (
  'background': #ffffff,
  'headerBackground': var(--background),
  'primary': $primary,
  'primaryShade': #54656b,
  'accent': $accent,
  'warning': $warning,
  'todo': $todo,
  'lightWarning': rgb(251 195 195 / 41%),
  'darkWarning': darken($warning, 10%),
  'success': $success,
  'darkSuccess': darken($success, 10%),
  'lightSuccess': lighten($success, 20%),
  'highlight': rgb(255 255 237 / 92%),
  'textColor': #00313e,
  'idleTextColor': rgba(0, 0, 0, 0.6),
  'textOnDark': #ffffff,
  'mutedTextOnDark': #ffffff99,
  'tableHover': hsl(hue($primary), 14%, 74%),
  'panelForeground': var(--textColor),
  'panelBackground': var(--background),
  'panelBack': hsl(hue($primary), 14%, 77%),
  'panelBackLight': hsl(hue($primary), 13%, 95%),
  'panelBackDarker': hsl(hue($primary), 15%, 91%),
  'grey': #757575,
  'lightGrey': #c5c5c5,
  'borderColor': rgba(0, 0, 0, 0.12),
  'greyHover': #e8e8e8,
  'bgContrast': #333333,
  'bgGrey': hsl(0, 5%, 96%),
  'collapseExpand': #799299,
  'muted': hsla(hue($primary), 10%, 30%, 60%),
);
$dark: (
  'background': #2e2e33,
  'headerBackground': var(--background),
  'primary': $primary,
  'primaryShade': #54656b,
  'accent': $accent,
  'todo': $todo,
  'warning': $warning,
  'darkWarning': darken($warning, 10%),
  'lightWarning': rgb(251 195 195 / 41%),
  'success': $success,
  'darkSuccess': darken($success, 10%),
  'lightSuccess': lighten($success, 20%),
  'highlight': rgb(255 255 237 / 92%),
  'textColor': #ffffff,
  'idleTextColor': rgba(255, 255, 255, 0.6),
  'textOnDark': #ffffff,
  'mutedTextOnDark': #ffffff99,
  'tableHover': hsl(hue($primary), 14%, 74%),
  'panelForeground': var(--textColor),
  'panelBackground': var(--background),
  'panelBack': hsl(hue($primary), 14%, 77%),
  'panelBackLight': hsl(hue($primary), 13%, 95%),
  'panelBackDarker': hsl(hue($primary), 15%, 91%),
  'grey': #757575,
  'lightGrey': #c5c5c5,
  'borderColor': rgba(0, 0, 0, 0.12),
  'greyHover': #e8e8e8,
  'bgContrast': #333333,
  'bgGrey': #f5f4f4,
  'collapseExpand': #799299,
  'muted': hsla(hue($primary), 14%, 20%, 60%),
);

// Fonts
$baseFont: 'ABCDiatype, sans-serif';
$baseFontSize: 12px;
$baseLineHeight: 20px;
$borderRadius: 7px;
$elementHeight: 2.3rem;
$panelHeaderHeight: 4.75rem;

$spacerTop: 12.5rem;
$breadCrumbSpacer: -6rem;

// Media breakpoints
$break1: 880px;
$break2: 1088px;
