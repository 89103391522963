.mat-mdc-dialog-surface > * {
  --mdc-dialog-subhead-color: var(--background);
  > header {
    --mdc-icon-button-icon-color: var(--background);
    // margin: -2rem -2rem 0 -2rem;
    padding: 0.5rem 2rem 0.5rem 2rem;
    background: var(--primary);
    display: flex;
    place-items: center;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      flex: 1;
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
