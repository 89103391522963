.mat-mdc-paginator {
  border-radius: 7px;
  // margin: 0.3rem;
  .mat-mdc-paginator-container {
    width: auto;
    margin: 0 auto;
    width: auto;
    place-items: baseline;
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mdc-notched-outline {
          background-color: var(--background);
          z-index: 0;
          overflow: hidden;
          border-radius: 4px;
          > * {
            border-color: transparent;
          }
        }
      }
    }
    .mat-mdc-paginator-range-actions {
      display: grid;
      grid-template: 'prev label next';
      .mat-paginator-range-label {
        grid-area: label;
        margin: 0;
      }
      .mat-paginator-navigation-previous {
        grid-area: prev;
      }
      .mat-paginator-navigation-next {
        grid-area: next;
      }
    }
  }
}
