@import 'variables';

bs-sidebar {
  width: 100%;
  --sidebarBackground: var(--background);
  &.resizable {
    --sidebarBackground: var(--panelBackDarker);
  }
  background-color: var(--sidebarBackground);
  border-radius: $borderRadius;
  aside > h2 {
    margin: 1rem 0 0 0 !important;
  }
  .shortcuts {
    .collapsible-panel-content {
      // padding: 0.5rem 0;
      // display: flex;
      // flex-wrap: wrap;
      // gap: 0.5rem;
      .row {
        flex: 1;
        min-width: 10rem;
        border-radius: $borderRadius;
        overflow: hidden;
        padding: 0.5rem 0 0 0;
        display: flex;
        flex-wrap: wrap;
      }
      button {
        flex: 1;
      }
    }
  }

  bs-collapsible-panel {
    header {
      background: var(--sidebarBackground);
      padding-top: 2.4rem;
      small {
        place-content: flex-end;
      }
    }
    table.list {
      font-size: 0.9rem;
      background: transparent;
      th {
        text-align: right;
        font-weight: normal;
      }
      td {
        font-weight: bold;
      }
    }
    .mat-list-base .mat-list-item {
      min-width: calc(100% + 2rem);
      padding: 0 1rem !important;
      margin: 0 -1rem 0 -1rem;
    }
    .mat-mdc-cell {
      border-bottom-width: 0;
    }
  }
  footer {
    margin-top: 2rem;
  }
  table {
    width: 100%;
    background: var(--sidebarBackground);
    margin: 1rem 0;
    border-radius: $borderRadius;
    // border-collapse: collapse;
    font-size: 0.9rem;
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    thead th {
      border-bottom: 1px dotted var(--primary);
    }
    th,
    td {
      line-height: normal;
      padding: 0 0.4rem;
    }
    th {
      font-size: 1rem;
      font-weight: bold;
    }
    td {
      font-size: 0.9rem;
    }
    &.list {
      background: transparent;
      th {
        text-align: right;
        font-weight: normal;
        padding-right: 0.5rem;
      }
      td {
        font-weight: bold;
        padding-left: 0.5rem;
        > * {
          display: block;
        }
      }
    }

    .name {
      font-weight: normal;
      text-align: left;
      border-bottom: 0;
    }
    .element {
      text-align: right;
      width: 50%;
      min-width: 50%;
      max-width: 50%;
    }
    .cost {
      font-weight: 200;
      text-align: right;
      width: 20%;
      max-width: 20%;
      min-width: 20%;
    }
    .amount {
      text-align: right;
      width: 30%;
      max-width: 30%;
      min-width: 30%;
    }
  }
  table.headline,
  table.footnote {
    th {
      font-weight: bold;
      border-bottom: 0;
    }
  }
}
